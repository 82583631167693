<template>
  <div class="auth-wrapper auth-wrapper-reset-password auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">

        <!-- logo -->
        <b-link class="brand-logo">
          <b-img
            :src="logoImg"
            alt="Payrite"
            style="width:150px"
          />
        </b-link>

        <b-card-title class="mb-1">
          Activeer uw account
        </b-card-title>
        <b-card-text class="mb-2" v-if="activateStatus === 0">
          Een ogenblik geduld...
        </b-card-text>
        <b-card-text class="mb-2" v-if="activateStatus === 1">
          Uw account is successvol geactiveerd, u kunt nu inloggen en gebruik maken van het platform!
        </b-card-text>
        <b-card-text class="mb-2" v-if="activateStatus === -1">
          Er is iets fout gegaan bij het activeren van je account, probeer het nogmaals. Indien u hulp nodig heeft kunt u contact openmen met onze helpdesk.
        </b-card-text>
        <b-card-text
          class="text-center mt-2"
        >
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon"/>
            Terug naar login
          </b-link>
        </b-card-text>

      </b-card>
      <!-- /Reset Password v1 -->
    </div>
  </div>

</template>

<script>
import {
  BCard, BCardText, BCardTitle, BImg,
  BLink,
} from 'bootstrap-vue'
import store from '@/store/index'
import authenticationStoreModule from '@/views/pages/authentication/authenticationStoreModule'
import { onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    BCard,
    BLink,
    BImg,
    BCardTitle,
    BCardText,
  },
  mixins: [heightTransition],
  data() {
    return {
      /* eslint-disable global-require */
      logoImg: require('@/assets/images/logo/payrite.png'),
      hasError: false,
      activateStatus: 0,
    }
  },
  mounted() {
    store.dispatch('auth-activate-account/activateAccount', { token: this.$route.query.token })
      .then(response => {
        this.hasError = false
        if (response.data.success) {
          this.activateStatus = 1
        }
        if (!response.data.success) {
          this.activateStatus = -1
        }
      })
      .catch(() => {
        this.activateStatus = -1
      })
  },
  setup() {
    const ACTIVATE_ACCOUNT_APP_STORE_MODULE_NAME = 'auth-activate-account'

    // Register module
    if (!store.hasModule(ACTIVATE_ACCOUNT_APP_STORE_MODULE_NAME)) store.registerModule(ACTIVATE_ACCOUNT_APP_STORE_MODULE_NAME, authenticationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACTIVATE_ACCOUNT_APP_STORE_MODULE_NAME)) store.unregisterModule(ACTIVATE_ACCOUNT_APP_STORE_MODULE_NAME)
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.auth-wrapper-reset-password{
  background: url("../../../assets/images/pages/activate.jpg");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

[dir] .auth-wrapper.auth-v1 .auth-inner:before {
  background-image: none !important;
}
</style>
